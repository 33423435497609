'use client';

import clsx from 'clsx';
import { useContext, useRef } from 'react';
import type { ReactElement, RefObject } from 'react';
import {
  type ComboBoxProps as AriaComboBoxProps,
  ComboBox as ComboBoxPrimitive,
  ComboBoxStateContext,
  Header,
  Input as InputPrimitive,
  ListBox,
  ListBoxItem as ListBoxItemPrimitive,
  type ListBoxItemProps,
  ListBoxSection as Section,
  type SectionProps,
} from 'react-aria-components';

import { Popover } from '@icelandia/ui/popover';
import { Trigger, TriggerLabel } from '@icelandia/ui/trigger';
import { twMerge } from '@icelandia/ui/utils/class-names';

export function ListBoxHeader({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <Header className={clsx(className, 'text-cap-base')}>{children}</Header>
  );
}

interface ListBoxSectionProps<T extends object>
  extends Omit<SectionProps<T>, 'children' | 'className'> {
  children: React.ReactNode | ((item: T) => ReactElement);
}

export function ListBoxSection<T extends object>({
  children,
  ...rest
}: ListBoxSectionProps<T>) {
  return (
    <Section className="flex flex-col gap-2" {...rest}>
      {children}
    </Section>
  );
}

export function ListBoxItem(props: ListBoxItemProps) {
  return (
    <ListBoxItemPrimitive
      className="-mx-2 flex items-center gap-2 rounded px-2 py-1 outline-none focus:bg-neutral-30 selected:bg-neutral-30"
      {...props}
    />
  );
}

interface BaseComboBoxProps<T extends object>
  extends Omit<AriaComboBoxProps<T>, 'clasName'> {
  comboBoxRef?: RefObject<HTMLDivElement>;
  className?: string;
}

export function BaseComboBox<T extends object>({
  comboBoxRef,
  children,
  className,
  ...rest
}: BaseComboBoxProps<T>) {
  return (
    <div ref={comboBoxRef}>
      <ComboBoxPrimitive
        menuTrigger="focus"
        className={twMerge('group', className)}
        {...rest}
      >
        {children}
      </ComboBoxPrimitive>
    </div>
  );
}

export function Input({
  placeholder,
  ariaLabel,
  name,
  autoFocus,
}: {
  placeholder?: string;
  ariaLabel: string;
  name?: string;
  autoFocus?: boolean;
}) {
  const state = useContext(ComboBoxStateContext)!;
  return (
    <InputPrimitive
      name={name}
      aria-label={ariaLabel}
      onClick={() => {
        state.open();
      }}
      className="w-full bg-transparent text-body-base leading-none outline-none"
      placeholder={placeholder}
      autoFocus={autoFocus}
    />
  );
}

interface ComboBoxProps<T extends object> extends BaseComboBoxProps<T> {
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  name?: string;
  allowsCustomValue?: boolean;
  items?: Array<T>;
  inputValue?: string;
  onInputChange?: (value: string) => void;
}

export function ComboBoxNew<T extends object>({
  label,
  children,
  placeholder,
  isRequired,
  name,
  allowsCustomValue,
  items,
  inputValue,
  onInputChange,
  ...rest
}: ComboBoxProps<T>) {
  const triggerRef = useRef<HTMLDivElement>(null!);

  return (
    <BaseComboBox
      comboBoxRef={triggerRef}
      isRequired={isRequired}
      allowsCustomValue={allowsCustomValue}
      items={items}
      inputValue={inputValue}
      onInputChange={onInputChange}
      {...rest}
    >
      <Trigger>
        <TriggerLabel>{label}</TriggerLabel>
        <Input ariaLabel={label} placeholder={placeholder} name={name} />
      </Trigger>
      <Popover triggerRef={triggerRef}>
        <ListBox className="flex max-h-[300px] flex-col overflow-auto">
          {children}
        </ListBox>
      </Popover>
    </BaseComboBox>
  );
}
